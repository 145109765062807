<template>
  <b-card no-body>
    <b-table
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        {{ data.item.title }}
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            size="sm"
            variant="outline-primary"
            :to="$route.path + '/edit/' + data.item.id"
          >
            <FeatherIcon icon="EditIcon" />
            Güncelle
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BButton,
} from 'bootstrap-vue'

const tableName = 'components'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
        ],
        where: {
          'components.seo': 1,
        },
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['components/dataList']
    },
    saveData() {
      return this.$store.getters['components/dataSaveStatus']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('components/getDataList', this.dataQuery)
    },
  },
}
</script>
